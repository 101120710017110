<template>
  <div>
    <section class="page-content">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-4 col-md-4 m-top-10">
                  <input
                    class="form-control"
                    :placeholder="`${$t('generic-str.search-by.default')}...`"
                    v-model="form.name.lk"
                  />
                </div>
                <div class="col-lg-2 col-md-2 m-top-10">
                  <div class="form-group">
                    <div class="input-group">
                      <div class>
                        <button class="btn btn-danger" @click="fetch(1)">
                          {{$t('generic-str.filter')}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6  m-top-10 text-right">
                  <div class="to-right">
                    <router-link
                        class="btn btn-success"
                        type="button"
                        to="/mail/send"
                      >
                        <i class="icon dripicons-plus"></i>{{$t('sms.campaigns.lbl-new-campaign')}}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!campaigns.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i class="fas fa-bullhorn font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('sms.campaigns.none')}}</h5>
                  <p>{{$t('sms.campaigns.lbl-first-campaing')}}</p>
                  <router-link
                    to="/mail/send"
                    type="button"
                    class="btn btn-success"
                    >{{$t('sms.campaigns.lbl-new-campaign')}}</router-link
                  >
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="campaigns.length" class="bg-light">
                    <tr>
                      <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">{{$t('generic-str.lbl-sender')}}</th>
                      <th scope="col">{{$t('generic-str.date-create')}}</th>
                      <th scope="col">{{$t('generic-str.date-sent')}}</th>
                      <th scope="col">Status</th>
                      <th scope="col">{{$tc('generic-str.message', 2)}}</th>
                      <th scope="col">{{$t('generic-str.current-value')}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="campaign in campaigns" :key="campaign.id">
                      <td>{{ campaign.name }}</td>
                      <td>{{ campaign.sender }}</td>
                      <td>
                        {{ campaign.created_at | date('DD/MM/YYYY HH:mm:ss') }}
                      </td>
                      <td>
                        {{
                          campaign.scheduled_at | date('DD/MM/YYYY HH:mm:ss')
                        }}
                      </td>
                      <td>
                        <span
                          v-if="campaign.status == 'queued'"
                          class="badge badge-light"
                          >{{$t('generic-str.scheduled')}}</span
                        >
                        <span
                          v-else-if="campaign.status == 'in_progress'"
                          class="badge badge-info"
                          >{{$t('generic-str.status.lbl-sending')}}</span
                        >
                        <span
                          v-else-if="campaign.status == 'paused'"
                          class="badge badge-warning"
                          >{{$t('generic-str.paused-a')}}</span
                        >
                        <span
                          v-else-if="campaign.status == 'sent'"
                          class="badge badge-success"
                          >{{$tc('generic-str.sent-a', 1)}}</span
                        >
                        <span
                          v-else-if="campaign.status == 'stopped'"
                          class="badge badge-danger"
                          >{{$t('generic-str.no-funds')}}</span
                        >
                      </td>
                      <td>{{ campaign.total_messages }}</td>
                      <td>{{ campaign.price | currency4D }}</td>
                      <td>
                        <span
                          class="col c-pointer"
                          v-modal="{
                            target: 'preview-modal',
                            data: campaign.body,
                          }"
                          @click.prevent="mountPreview(campaign.body)"
                        >
                          <i class="icon dripicons-information"></i>
                        </span>
                        <router-link
                          v-if="campaign.status != 'queued'"
                          :to="`campaigns/${campaign.id}`"
                          class="col"
                          v-tooltip.top="$tc('generic-str.message', 2)"
                        >
                          <i class="icon dripicons-mail"></i>
                        </router-link>
                        <router-link
                          v-if="campaign.status != 'queued'"
                          :to="{
                            path: 'insights',
                            query: {
                              campaign_id: campaign.id,
                              from: campaign.scheduled_at,
                            },
                          }"
                          class="col"
                          v-tooltip.top="$tc('generic-str.report', 2)"
                        >
                          <i class="icon dripicons-graph-pie"></i>
                        </router-link>
                        <a
                          v-if="campaign.status == 'in_progress'"
                          @click="pause(campaign.id)"
                          href="javascript:void(0)"
                          class="col"
                          v-tooltip.top="$t('generic-str.pause')"
                        >
                          <i class="icon dripicons-media-pause"></i>
                        </a>
                        <a
                          v-if="
                            campaign.status == 'stopped' ||
                            campaign.status == 'paused'
                          "
                          @click="play(campaign.id)"
                          href="javascript:void(0)"
                          class="col"
                          v-tooltip.top="$t('generic-str.send')"
                        >
                          <i class="icon dripicons-media-play"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="col"
                          v-if="campaign.status === 'queued'"
                          v-modal="{
                            target: 'edit-campaign-modal',
                            data: campaign,
                          }"
                          v-tooltip.top="$t('generic-str.edit')"
                        >
                          <i class="icon dripicons-pencil"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="col"
                          v-if="campaign.status === 'queued'"
                          v-tooltip.top="$t('generic-str.remove')"
                          @click="remove(campaign.id)"
                        >
                          <i class="icon dripicons-cross"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <email-campaign-modal id="edit-campaign-modal" @submit="fetch(1)" />
    <preview-modal id="preview-modal" ref="preview-modal"></preview-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
// import PageHeader from '@/components/PageHeader.vue';
import MailService from '@/services/mail.service';
import Pagination from '@/components/Pagination.vue';
import EmailCampaignModal from '@/components/mails/campaigns/EmailCampaignModal.vue';
import PreviewModal from '@/components/mails/PreviewModal.vue';

export default {
  name: 'MailCampaigns',
  components: {
    // PageHeader,
    Pagination,
    EmailCampaignModal,
    PreviewModal,
  },
  data() {
    return {
      fetched: false,
      campaigns: [],
      pages: 1,
      form: {
        page: 1,
        name: {},
      },
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    mountPreview(bodyParam) {
      this.$root.$emit('updated.previewmail', { body: bodyParam });
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      MailService.getCampaigns(this.form).then(
        (response) => {
          this.fetched = true;
          this.campaigns = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    pause(id) {
      MailService.editCampaign({ status: 'paused', id: `${id}` }).then(
        () => {
          this.fetch(this.form.page);
        },
        (error) => {
          this.$toast.show({
            title: 'Erro',
            content: error.message,
            type: 'danger',
          });
        },
      );
    },
    play(id) {
      MailService.editCampaign({ status: 'in_progress', id: `${id}` }, id).then(
        () => {
          this.fetch(this.form.page);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(campaignId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('warnings.campaign.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          MailService.deleteCampaign(campaignId).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(this.$t('generic-str.deleted'), this.$t('warnings.campaign.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
